import React, { useState, useEffect } from 'react';
import Banneruser from "../../assets/images/banner-user.png"

function Banner() {
    return (

        <div className='Banner'>
            <div className='Banner-main-head'>Here's your content side-kick</div>
            <div className='Banner-sub-head'>Get scripts • Get shot breakdowns • Shoot quality content </div>
            <div className='banner-para'>
                <div>From scripting to shot breakdowns, we are your one-stop-shop to create cooooool videos!<br/>
               
                No more staring at the blank screen, because FlickVid is here as your pocket-friendly content wildcard.
                </div>
                
            </div>
            <div className='text-center'>
                <div className='download-app-btn'>
                    Download App
                </div>
            </div>
            <div className='user-img'>
                <img src={Banneruser} alt="Banneruser" className='img-fluid' width="45" height="45"/>
            </div>
        </div>
    )
}

export default Banner;