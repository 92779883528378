import React, { useState, useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Quotes from "../../assets/images/quote.png"
function Testimonials() {
    var settings = {
        infinite: true,
        speed: 500,
        dots: false,
        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              centerMode: false,
            }
          },
        ]
    };
    return (

        <div className='Testimonials'>
            <div className='testimonial-head mb-4'>…And that’s what the buzz is about!
            </div>
            <Slider {...settings}>
                <div className='text-center'>
                    <div className='testimonial-wraps'>
                        <div className='mb-3'>
                            <img src={Quotes} alt="Quotes" className='img-fluid' width="80" height="80" />
                        </div>
                        <div className='testimnoial-para'>
                        My engagement has gone up since I started using it, and the feedback I get on my videos is incredible
                        </div>
                        <div className='testimonial-name'>
                            ~ Samika Agrawal
                        </div>
                    </div>

                </div>
                <div className='text-center'>
                    <div className='testimonial-wraps'>
                        <div className=' mb-3'>
                            <img src={Quotes} alt="Quotes" className='img-fluid' width="80" height="80" />
                        </div>
                        <div className='testimnoial-para'>
                            FlickVid has completely transformed the way I create content! As someone who’s always on the go, I love how easy it is to generate scripts and get shot-by-shot breakdowns tailored to my ideas. Highly Recommended from my end.
                        </div>
                        <div className='testimonial-name'>
                            ~ Samika Agrawal
                        </div>
                    </div>

                </div>
                <div className='text-center'>
                    <div className='testimonial-wraps'>
                        <div className=' mb-3'>
                            <img src={Quotes} alt="Quotes" className='img-fluid' width="80" height="80" />
                        </div>
                        <div className='testimnoial-para'>
                        This app is a lifesaver! I used to struggle with coming up with video ideas and editing them, but FlickVid makes the entire process super easy. The script claim feature is a great bonus because now I know my work is protected. Plus, the camera tool is really smart—it even suggests the best angles for my shots!"
                        </div>
                        <div className='testimonial-name'>
                        ~ Kalll Agrawal
                        </div>
                    </div>

                </div>
            </Slider>
        </div>
    )
}

export default Testimonials;