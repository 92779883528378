import React, { useState, useEffect } from 'react'
import Header from '../../layout/header'
import Footer from '../../layout/footer'
import FooterBanner from './footer-banner'
import ContactForm from './contact-form'
import ContactImg from '../../assets/images/contact.png'

function Contact() {
  return (
    <div className='home-wrapper main-wrapper  main-wrapper-cms'>
      <div>
        <div className='gradient-wrap'>
          <Header />
          <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className='contact-head'>
                <span className='text-pink'>FlickVid </span>
                 Labs
                </div>
              <div className='about-para'>
                FlickVid Labs is a creative ecosystem. Founded on the belief that talent thrives when nurtured and innovation flourishes through collaboration,<br/>
                we are dedicated to shaping the future of entertainment, content <br/>creation, and artistry.
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className='mt-lg-5'>
                <img src={ContactImg} alt="ContactImg" className='img-fluid' width="100%" height="100%"/>
              </div>
            </div>
          </div>
          <div>
            <div className='what-we-do'>
              <span className='text-pink'>What</span> We Do:</div>
            <div className="row">
              <div className="col-12 col-lg-6 pe-lg-4">
                <div className='contact-cards'>
                  <div className='contact-card-head'><span className='text-pink'>Talent
                    </span> Management:</div>
                  <div className='about-para'>
                    We represent a diverse roster of creators, helping them unlock their full potential and achieve their career goals. From strategising growth to securing opportunities, we ensure our talent shines on every stage.
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className='contact-cards'>
                  <div className='contact-card-head'><span className='text-pink'>Talent</span> Creation:</div>
                  <div className='about-para'>
                    At FlickVid Labs, we don’t just manage talent; we cultivate it. Our in-house initiatives identify emerging creators, providing them with the tools, training, and mentorship they need to transform their passions into professions.
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 pe-lg-4">
                <div className='contact-cards'>
                  <div className='contact-card-head'><span className='text-pink'>Collaboration</span> Hub:</div>
                  <div className='about-para'>
                    We connect visionaries, from filmmakers to content creators and brands, fostering collaborations that break boundaries and redefine industries.
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className='contact-cards'>
                  <div className='contact-card-head'><span className='text-pink'>Creator</span> Nurturing:</div>
                  <div className='about-para'>
                    We provide ongoing support for creators to grow, evolve, and excel. Through workshops, resources, and personalised guidance, we help talent stay ahead in a rapidly changing world.
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
         
        </div>
        <div className='container'>
          
          
          <div className='mb-lg-5'>
            <div className='our-mission'>
            <span className='text-pink'>Our</span> Mission
            </div>
            <div className='about-para'>
            <p>Our mission is to empower creators, amplify their voices, and bring bold ideas to life.  Whether you're a seasoned artist, an aspiring creator, or a partner looking for innovative collaborations, FlickVid Labs is where your journey begins.</p>
            <p>Welcome to FlickVid Labs – Where Creativity Finds its Home.</p>
            </div>
          </div>
          <div className='mt-5'>          
            <div className='our-mission'>
            <span className='text-pink'> Get in </span> Touch
            </div>
           <ContactForm/>
          </div>

        </div>

      </div>

      <FooterBanner />
      <Footer />
    </div>
  )
}

export default Contact