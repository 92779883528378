import React, { useState, useEffect } from 'react'
import Header from '../../layout/header'
import Footer from '../../layout/footer'
import FooterBanner from './footer-banner'
import ContactForm from './contact-form'
import ContactImg from '../../assets/images/contact.png'

function Privacy() {
  return (
    <div className='home-wrapper main-wrapper  main-wrapper-cms'>
      <div>
        <div className='gradient-wrap'>
          <Header />


        </div>
        <div className='container terms-div px-lg-5'>


          <div className='mb-lg-5 px-lg-5'>
            <div className='our-mission'>
              <span className='text-pink'>PRIVACY</span> POLICY
            </div>
            <div className='about-para'>
              <p>Your privacy is important to us, and we are committed to protecting your personal data. By using
                our Services, you acknowledge and agree to the terms outlined in our Privacy Policy, which is
                fully incorporated into the Legal Terms. We encourage you to review our Privacy Policy to
                understand how we collect, use, disclose, and protect your information.</p>
              <p>Please note that the Services are hosted in India. If you access the Services from a country or
                region outside India that has laws governing data collection, usage, or disclosure that differ from
                Indian law, you acknowledge and agree that by continuing to use the Services:</p>

              <ul>
                <li>Your personal data will be transferred to India.</li>
                <li>You expressly consent to this transfer and the subsequent processing of your data in
                  accordance with Indian laws and our Privacy Policy.</li>
              </ul>

              <p>
                We take reasonable steps to ensure the security of your data, but by using the Services, you
                accept the inherent risks associated with data transfer and storage in a global digital
                environment. If you have questions or concerns about your privacy, please contact us as
                outlined in our Privacy Policy.
              </p>

              <div>
                FlickVid Martech Private Limited?<br />
                1704 and 1705, Panchleela, Panchshristi complex, Hirananandani gardens,<br />
                Mumbai , Maharashtra 400076<br />
                India<br />
                Phone: 9820-816894<br />
                shray@flickvid.ai
              </div>
            </div>
          </div>


        </div>

      </div>

      <FooterBanner />
      <Footer />
    </div>
  )
}

export default Privacy