
import axios from "axios";

const getDetailsPageWiseURL = 'https://mqpzij5tcf.execute-api.ap-south-1.amazonaws.com/dev'

export function apiCallingPost(postData) {
  return axios.post(getDetailsPageWiseURL, postData)
    .then((data) => data)
    .then((res) => res)
}
