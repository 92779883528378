import React, { useState } from 'react';
import { apiCallingPost } from "../../service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ContactForm() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [profession, setProfession] = useState('');
    const [lookingFor, setLookingFor] = useState('');
    const [message, setMessage] = useState('');


    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        profession: '',
        lookingFor: '',
        message: '',
    });


    const handleSubmit = (e) => {  
        document.getElementById("submit").disabled = true;
        e.preventDefault();
        const newErrors = {};
        if (!firstName) newErrors.firstName = 'First Name is required';
        if (!lastName) newErrors.lastName = 'Last Name is required';
        if (!email) newErrors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Please enter a valid email';
        if (!phoneNumber) newErrors.phoneNumber = 'Phone Number is required';
        else if (!/^\d{10}$/.test(phoneNumber)) newErrors.phoneNumber = 'Phone Number must be exactly 10 digits';
        // if (!profession) newErrors.profession = 'Profession is required';
        // if (!lookingFor) newErrors.lookingFor = 'What are you looking for? is required';
        // if (!message) newErrors.message = 'Message is required';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const postData = {
            "functionName": "saveContactSubmission",
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "phone_number": phoneNumber,
            "profession": profession,
            "looking_for": lookingFor,
            "message": message,
        }
        apiCallingPost(postData).then((data) => {
            document.getElementById("submit").disabled = false;
            if (data.data.success === "1") {
                toast.success(`Thanks for reaching out, ${firstName} ${lastName}! We appreciate you taking the time to contact us.`);
            }
            else{
                toast.error("Something Went Wrong.");
            }
        });

        console.log("Form Data:", postData);

        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setProfession('');
        setLookingFor('');
        setMessage('');
        setErrors({});
    };

    return (
        <form onSubmit={handleSubmit} className="row mb-5">
            <ToastContainer />
            <div className="col-12 col-lg-6 mb-4">
                <input
                    type="text"
                    className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
            </div>

            <div className="col-12 col-lg-6 mb-4">
                <input
                    type="text"
                    className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
            </div>

            <div className="col-12 col-lg-6 mb-4">
                <input
                    type="email"
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                    placeholder="Email Id"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>

            <div className="col-12 col-lg-6 mb-4">
                <input
                    type="text"
                    className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber}</div>}
            </div>

            <div className="col-12 col-lg-6 mb-4">
                <input
                    type="text"
                    className={`form-control ${errors.profession ? 'is-invalid' : ''}`}
                    placeholder="Profession"
                    value={profession}
                    onChange={(e) => setProfession(e.target.value)}
                />
                {errors.profession && <div className="invalid-feedback">{errors.profession}</div>}
            </div>

            <div className="col-12 col-lg-6 mb-4">
                <input
                    type="text"
                    className={`form-control ${errors.lookingFor ? 'is-invalid' : ''}`}
                    placeholder="What are you looking for?"
                    value={lookingFor}
                    onChange={(e) => setLookingFor(e.target.value)}
                />
                {errors.lookingFor && <div className="invalid-feedback">{errors.lookingFor}</div>}
            </div>

            <div className="col-12 mb-4">
                <textarea
                    className={`form-control h-auto ${errors.message ? 'is-invalid' : ''}`}
                    rows="8"
                    placeholder="Any Messages"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                {errors.message && <div className="invalid-feedback">{errors.message}</div>}
            </div>

            <div className="text-center">
                <button className="submit" id="submit">
                    Submit
                </button>
            </div>
        </form>
    );
}

export default ContactForm;
