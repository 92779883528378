import React, { useState, useEffect } from 'react';
import Banner from "../../assets/images/footer-banner-bg.png"
import Playstore from "../../assets/images/google-play.png"
import Appstore from "../../assets/images/app-store.png"

function FooterBanner() {
    return (

        <div className='Footer-Banner'>
            <div className="container">
            <div className="row m-0 align-items-center my-5">
                <div className="col-12 col-lg-7">
                    <div className='footer-top-banner-text'>

                    From the <span className='text-pink'>perfect script</span> to the <span className='text-pink'>perfect shot,</span><br/>
                    <span className='text-pink'>FlickVid</span> covers it all!
                    </div>

                </div>
                <div className="col-12 col-lg-5">
                    <div className='col-12 text-center mb-3'>
                                <img src={Banner} alt="Appstore" className='img-fluid bottom-footer-img' width="200" height="auto"/>
                            </div>
                
                    <div className="row">
                        <div className="col-6 text-center">
                            <div>
                                <img src={Appstore} alt="Appstore" className='img-fluid' width="200" height="auto"/>
                            </div>
                        </div>
                        <div className="col-6 text-center">
                            <div>
                                <img src={Playstore} alt="Playstore" className='img-fluid' width="200" height="auto"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default FooterBanner;