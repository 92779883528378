
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './assets/css/bootstrap.min.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import Home from './pages/home/home';
import Contact from './pages/home/contact';
import About from './pages/home/about';
import Privacy from './pages/home/privacy-policy';
import Terms from './pages/home/terms-condition';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/privacy' element={<Privacy />}></Route>
        <Route path='/terms-and-condition' element={<Terms />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
