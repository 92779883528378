import React, { useState, useEffect } from 'react'
import Header from '../../layout/header'
import Footer from '../../layout/footer'
import FooterBanner from './footer-banner'
import Banner from './banner'
import Features from './features'
import Testimonials from './testimonials'

function Home() {

  return (
    <div className='home-wrapper main-wrapper  main-wrapper-cms'>
      {/* <div className='top-header'>
      </div> */}
      <div>
        <div className='gradient-wrap'>
          <Header />
          <div className="container">
            <Banner />
          </div>
        </div>
        <Features />

      </div>
      <Testimonials/>
      <FooterBanner/>
      <Footer />
    </div>
  )
}

export default Home